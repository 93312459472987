import {LayoutConfig} from "../types/LayoutConfig";

const NO_LAYOUT_CONFIG: LayoutConfig = {
  id: "",
  schedule: {
    always: true
  },
  panels: []
}

export class LayoutService {
  static configs: LayoutConfig[] = [];

  static async init(): Promise<void> {
    try {
      LayoutService.configs = await fetchActiveConfigs();
      const autoRefreshInterval = 1000 * 60 * 10;

      setInterval(() => {
        fetchActiveConfigs()
          .then(cfg => this.configs = cfg)
          .catch(_ => console.log("cannot refresh layout config"));
      }, autoRefreshInterval);
    } catch (e) {
      console.error("LayoutService could not init", e)
    }
  }

  static getActiveLayout(): LayoutConfig {
    const now = new Date();

    const activeConfigs = this.configs.filter(config => {
      if(config.schedule.always) {
        return true;
      }

      return config.schedule.times.reduce((accu, curr) => {
        if(accu) return true;
        return (new Date(curr.from) <= now && new Date(curr.to) >= now);
      }, false);
    });

    const defaultConfig = this.configs.filter(config => config.id === "default").at(0);
    return activeConfigs.at(0) ?? defaultConfig ?? NO_LAYOUT_CONFIG;
  }
}

async function fetchActiveConfigs(): Promise<LayoutConfig[]> {
  const activeConfigs = await fetch("/activeConfigs.json").then(content => content.json());

  const configFetches = (activeConfigs as string[])
    .map(configPath => fetch(configPath).then(content => content.json()));

  const configs: LayoutConfig[] = (await Promise.all(configFetches)).map(item => item as LayoutConfig);
  return configs;
}
